import Select from 'react-select'
import restClient from "Shared/hooks/restClient"
import './ClientSwitcher.scoped.scss'

const AdminUserTools = ({client_id, clients}) => {

  const clientOptions = _.orderBy(clients.map(client =>
    client.id === client_id ?
      { value: client.id, label: `${client.name} (current)` } :
      { value: client.id, label: client.name }
  ), 'label')
  const currentClientOption = clientOptions.find(option => option.value === client_id)

  const handleClientChange = (selectedOption) => {
    const payload = { clientId: selectedOption.value }
    restClient.post('/api/users/me/switch_client', payload)
    .then(({ data }) => {
      window.location.href = data.url
    })
    .catch((e) => { console.error(e) })
  }

  return (
    <div id="admin-user-tools">
      <div className="field" id="switchClient">
        <Select
          id="client-select"
          className="basic-single"
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuShouldScrollIntoView={true}
          defaultValue={currentClientOption}
          options={clientOptions}
          onChange={handleClientChange}
        />
      </div>
    </div>
  )
}

export default AdminUserTools
